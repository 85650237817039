.slick-prev,
.slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 30px;
    height: 42px;
    padding: 0;
    transform: translate(0, -50%);
    cursor: pointer;
    color: blue;
    border: none;
    outline: none;
    background: #f9fafb;
    border-radius: 5px;
}

.slick-slide {
    height: auto;
    padding: 10px;
}

.slick-track {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: stretch;
}

.slick-dots li button:before {
    font-family: 'slick';
    font-size: 13px !important;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 22px;
    height: 30px;
    content: '•';
    text-align: center;
    opacity: .35;
    color: #1f4a88 !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 34px;
    height: 37px;
    padding: 5px;
    cursor: pointer;
    color: #3c7edb;
    border: 0;
    outline: none;
    background: transparent;
}


/* .slide-two {
    cursor: pointer;
    transform: translate(0%, 0px);
    width: 100%;
}

.slide-two:hover {
    position: absolute;
    overflow: hidden;
    transform: scale(1.1) !important;
} */

.slide-two {
    overflow: hidden;
    padding: 0;
    border: 1px solid #000;
    float: left;
    margin: 10px;
}

.slide-two img {
    transition-duration: 1s;
    margin: 0 auto;
    display: block;
}

.slide-two img:hover {
    transform: scale(0.1);
    -webkit-transform: scale(1.1);
    -moz-transform: scale(3.1);
    z-index: 0;
    border-radius: 12px !important;
}