.sec-01 {
    background-color: indigo;
}


/* Extra small devices (phones, 600px and down) */

@media only screen and (max-width: 600px) {
    .left-cards {
        min-height: 300px;
        height: 398px;
        padding: 10px;
        background: url(../../static/svg/card_11.svg);
        background-size: 100%;
        background-position: right bottom;
        background-repeat: no-repeat;
        right: 0px;
        bottom: 0px;
        width: 97%;
        background-color: #1F4A88 !important;
        color: #fff;
        margin: auto;
        margin-bottom: 30px;
        border-radius: 9px;
    }
    .leftCardHeading {
        font-size: 26px;
        text-align: start;
        padding-left: 18px;
        padding-top: 2px;
        padding-bottom: 4px;
        font-weight: bold;
    }
    .leftCardText {
        font-size: 14px;
        padding-left: 19px;
        text-align: start;
        line-height: 1.8;
        overflow: hidden;
        width: 295px;
    }
    .right-card-1 {
        min-height: 150px;
        height: 245px;
        margin: auto;
        margin-bottom: 30px;
        padding: 13px 6px;
        background: url(../../static/svg/card_two.svg);
        background-size: 46% 62%;
        background-position: right bottom;
        background-repeat: no-repeat;
        right: 0px;
        width: 100%;
        /* text-align: center; */
        bottom: 0px;
        overflow: hidden;
        background-color: #1F4A88 !important;
        color: #fff;
        border-radius: 9px;
    }
    .rightCardHeading-1 {
        font-size: 26px;
        text-align: start;
        padding-left: 17px;
        padding-top: 4px;
        padding-bottom: 2px;
        font-weight: bold;
        line-height: 1.4;
    }
    .rightCardText-1 {
        font-size: 14px;
        padding-left: 19px;
        text-align: start;
        line-height: 1.8;
        padding-top: 11px;
        overflow: hidden;
        width: 199px;
    }
    .right-card-2 {
        min-height: 150px;
        height: 274px;
        margin: auto;
        margin-bottom: 30px;
        padding: 12px 6px;
        background: url(../../static/svg/card_one.svg);
        background-size: 38% 54%;
        background-position: right bottom;
        background-repeat: no-repeat;
        right: 0px;
        width: 100%;
        /* text-align: center; */
        bottom: 0px;
        overflow: hidden;
        background-color: #14B8E1 !important;
        color: #fff;
        border-radius: 9px
    }
    .rightCardHeading-2 {
        font-size: 26px;
        text-align: start;
        padding-left: 11px;
        padding-top: 6px;
        font-weight: bold;
        line-height: 1.4;
    }
    .rightCardText-2 {
        font-size: 15px;
        padding-left: 13px;
        text-align: start;
        padding-top: 11px;
        line-height: 1.6;
        width: 240px;
    }
}

@media only screen and (min-width: 600px) and (orientation: landscape) {
    .left-cards {
        min-height: 300px;
        height: 398px;
        padding: 10px;
        background: url(../../static/svg/card_11.svg);
        background-size: 100%;
        background-position: right bottom;
        background-repeat: no-repeat;
        right: 0px;
        bottom: 0px;
        width: 97%;
        background-color: #1F4A88 !important;
        color: #fff;
        margin: auto;
        margin-bottom: 30px;
        border-radius: 9px;
    }
    .leftCardHeading {
        font-size: 26px;
        text-align: start;
        padding-left: 18px;
        padding-top: 2px;
        padding-bottom: 4px;
        font-weight: bold;
    }
    .leftCardText {
        font-size: 14px;
        padding-left: 19px;
        text-align: start;
        line-height: 1.8;
        overflow: hidden;
        width: 295px;
    }
    .right-card-1 {
        min-height: 150px;
        height: 245px;
        margin: auto;
        margin-bottom: 30px;
        padding: 13px 6px;
        background: url(../../static/svg/card_two.svg);
        background-size: 46% 62%;
        background-position: right bottom;
        background-repeat: no-repeat;
        right: 0px;
        width: 100%;
        /* text-align: center; */
        bottom: 0px;
        overflow: hidden;
        background-color: #1F4A88 !important;
        color: #fff;
        border-radius: 9px;
    }
    .rightCardHeading-1 {
        font-size: 26px;
        text-align: start;
        padding-left: 17px;
        padding-top: 4px;
        padding-bottom: 2px;
        font-weight: bold;
        line-height: 1.4;
    }
    .rightCardText-1 {
        font-size: 14px;
        padding-left: 19px;
        text-align: start;
        line-height: 1.8;
        padding-top: 11px;
        overflow: hidden;
        width: 199px;
    }
    .right-card-2 {
        min-height: 150px;
        height: 274px;
        margin: auto;
        margin-bottom: 30px;
        padding: 12px 6px;
        background: url(../../static/svg/card_one.svg);
        background-size: 38% 54%;
        background-position: right bottom;
        background-repeat: no-repeat;
        right: 0px;
        width: 100%;
        /* text-align: center; */
        bottom: 0px;
        overflow: hidden;
        background-color: #14B8E1 !important;
        color: #fff;
        border-radius: 9px
    }
    .rightCardHeading-2 {
        font-size: 26px;
        text-align: start;
        padding-left: 11px;
        padding-top: 6px;
        font-weight: bold;
        line-height: 1.4;
    }
    .rightCardText-2 {
        font-size: 15px;
        padding-left: 13px;
        text-align: start;
        padding-top: 11px;
        line-height: 1.6;
        /* width: 240px; */
    }
}


/* Medium devices (landscape tablets, 600px and up) */

@media only screen and (min-width: 768px) {
    .left-cards {
        min-height: 300px;
        height: 540px;
        padding: 22px 34px;
        background: url(../../static/svg/card_11.svg);
        background-size: 100%;
        margin-bottom: 31px;
        background-position: bottom center;
        background-repeat: no-repeat;
        right: 0px;
        bottom: 0px;
        background-color: #1F4A88 !important;
        color: #fff;
        border-radius: 9px;
    }
    .leftCardHeading {
        font-size: 49px;
        text-align: start;
        padding-left: 18px;
        padding-top: 2px;
        padding-bottom: 4px;
        font-weight: bold;
    }
    .leftCardText {
        font-size: 16px;
        padding-left: 21px;
        text-align: start;
        line-height: 1.8;
        overflow: hidden;
        width: 449px;
    }
    .right-card-1 {
        min-height: 150px;
        height: 266px;
        margin-bottom: 30px;
        padding: 34px 34px;
        background: url(../../static/svg/card_two.svg);
        background-size: 31% 74%;
        background-position: right bottom;
        background-repeat: no-repeat;
        right: 0px;
        bottom: 0px;
        overflow: hidden;
        background-color: #1F4A88 !important;
        color: #fff;
        border-radius: 9px;
    }
    .rightCardHeading-1 {
        font-size: 47px;
        text-align: start;
        padding-left: 17px;
        line-height: 1.1;
        font-weight: bold;
    }
    .rightCardText-1 {
        font-size: 16px;
        padding-left: 18px;
        padding-top: 16px;
        text-align: start;
        line-height: 1.8;
        overflow: hidden;
        width: 439px;
    }
    .right-card-2 {
        min-height: 150px;
        height: 266px;
        padding: 34px 34px;
        background: url(../../static/svg/card_one.svg);
        background-size: 30% 73%;
        background-position: right bottom;
        background-repeat: no-repeat;
        right: 0px;
        bottom: 0px;
        background-color: #14B8E1 !important;
        color: #fff;
        border-radius: 9px;
    }
    .rightCardHeading-2 {
        font-size: 47px;
        text-align: start;
        padding-left: 17px;
        line-height: 1.1;
        font-weight: bold;
    }
    .rightCardText-2 {
        font-size: 16px;
        padding-left: 17px;
        padding-top: 17px;
        text-align: start;
        line-height: 1.6;
    }
}


/* Large devices (laptops/desktops, 992px and up) */

@media only screen and (min-width: 992px) {
    br {
        display: block !important;
    }
    .left-cards {
        min-height: 300px;
        height: 493px;
        padding: 10px;
        background: url(../../static/svg/card_11.svg);
        background-size: 100% 68%;
        background-position: bottom center;
        background-repeat: no-repeat;
        right: 0px;
        bottom: 0px;
        background-color: #1F4A88 !important;
        color: #fff;
        border-radius: 9px;
    }
    .leftCardHeading {
        font-size: 30px;
        text-align: start;
        padding-left: 25px;
        padding-top: 20px;
        font-weight: bold;
    }
    .leftCardText {
        font-size: 16px;
        padding-left: 25px;
        text-align: start;
        line-height: 1.6;
        width: 405px;
    }
    .rightCardHeading-1 {
        font-size: 30px;
        text-align: start;
        padding-left: 25px;
        padding-top: 20px;
        font-weight: bold;
    }
    .rightCardText-1 {
        font-size: 16px;
        padding-left: 25px;
        text-align: start;
        line-height: 1.6;
        width: 330px;
    }
    .right-card-1 {
        min-height: 150px;
        height: 225px;
        margin-bottom: 22px;
        padding: 0px;
        border-radius: 9px;
        background: url(../../static/svg/card_two.svg);
        background-size: 33% 62%;
        background-position: right bottom;
        background-repeat: no-repeat;
        right: 0px;
        bottom: 0px;
        background-color: #1F4A88 !important;
        color: #fff;
    }
    .right-card-2 {
        min-height: 150px;
        height: 246px;
        padding: 10px;
        border-radius: 9px;
        background: url(../../static/svg/card_one.svg);
        background-size: 33% 68%;
        background-position: right bottom;
        background-repeat: no-repeat;
        right: 0px;
        bottom: 0px;
        background-color: #14B8E1 !important;
        color: #fff;
    }
    .rightCardHeading-2 {
        font-size: 30px;
        text-align: start;
        padding-left: 17px;
        padding-top: 1px;
        font-weight: bold;
    }
    .rightCardText-2 {
        font-size: 16px;
        padding-left: 17px;
        text-align: start;
        line-height: 1.6;
    }
}


/* Extra large devices (large laptops and desktops, 1200px and up) */

@media only screen and (min-width: 1200px) {
    h5 {
        font-size: 17px;
    }
    .left-cards {
        height: 511px;
        background: url(../../static/svg/card_11.svg);
        background-size: 100% 91%;
        background-position: right bottom;
        background-repeat: no-repeat;
        right: 0px;
        bottom: 0px;
        background-color: #1F4A88 !important;
        color: #fff;
        padding-left: 28px;
        border-radius: 9px;
        margin-bottom: 22px;
    }
    .leftCardHeading {
        font-size: 38px;
        text-align: start;
        padding-left: 25px;
        padding-top: 20px;
        font-weight: bold;
    }
    .leftCardText {
        font-size: 15px;
        padding-left: 25px;
        text-align: start;
        line-height: 1.6;
        width: 457px;
    }
    .right-card-1 {
        height: 246px;
        margin-bottom: 23px;
        padding: 10px 10px 10px 25px;
        background: url(../../static/svg/card_two.svg);
        background-size: 34% 70%;
        background-position: right bottom;
        background-repeat: no-repeat;
        right: 0px;
        bottom: 0px;
        line-height: 1.2;
        background-color: #1F4A88 !important;
        color: #fff;
        border-radius: 9px;
    }
    .rightCardHeading-1 {
        font-size: 38px;
        text-align: start;
        padding-left: 25px;
        padding-top: 27px;
        font-weight: bold;
        line-height: 1.2;
    }
    .rightCardText-1 {
        font-size: 15px;
        padding-left: 25px;
        text-align: start;
        line-height: 1.6;
        width: 100%;
    }
    .right-card-2 {
        min-height: 150px;
        height: 240px;
        padding: 10px 10px 10px 25px;
        background: url(../../static/svg/card_one.svg);
        background-size: 34% 70%;
        background-position: right bottom;
        background-repeat: no-repeat;
        right: 0px;
        bottom: 0px;
        background-color: #14B8E1 !important;
        color: #fff;
        border-radius: 9px;
    }
    .rightCardHeading-2 {
        font-size: 38px;
        text-align: start;
        padding-left: 25px;
        padding-top: 20px;
        font-weight: bold;
        line-height: 1.2;
    }
    .rightCardText-2 {
        font-size: 15px;
        padding-left: 25px;
        text-align: start;
        line-height: 1.6;
    }
}