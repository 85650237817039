    /* Icon canvas 1 */
    
    .allCanvas {
        opacity: 0.8;
        fill: #14B8E1;
    }
    
    .iconCanvas-1 {
        width: 100px;
        height: 90px;
    }
    
    .iconCanvas-1:hover {
        cursor: pointer;
        color: white;
        border-bottom: 2px solid#14B8E1;
        box-shadow: inset 0px -96px 12px -3px rgb(20 184 225 / 10%);
    }
    
    .iconAllCanvasAct {
        cursor: pointer;
        color: white;
        width: 100px;
        height: 90px;
        border-bottom: 2px solid#14B8E1;
        box-shadow: inset 0px -96px 12px -3px rgb(20 184 225 / 10%);
    }
    
    .iconAllCanvasAct .allCanvas {
        fill: #ffffff !important;
    }
    
    .iconAllCanvasAct p {
        color: #ffffff !important;
    }
    /* Icon canvas 2 */
    
    .travelCanvasSvg {
        opacity: 0.8;
        fill: #14B8E1;
    }
    
    .iconCanvas-2 {
        width: 100px;
        height: 90px;
    }
    
    .iconCanvas-2:hover {
        cursor: pointer;
        color: white;
        border-bottom: 2px solid#14B8E1;
        box-shadow: inset 0px -96px 12px -3px rgb(20 184 225 / 10%);
    }
    
    .iconCanvasActive {
        cursor: pointer;
        color: white;
        width: 100px;
        height: 90px;
        border-bottom: 2px solid#14B8E1;
        box-shadow: inset 0px -96px 12px -3px rgb(20 184 225 / 10%);
    }
    
    .iconCanvasActive .travelCanvasSvg {
        fill: #ffffff !important;
    }
    
    .iconCanvasActive p {
        color: #ffffff !important;
    }
    /* Icon canvas 3 */
    
    .eventCanvasSvg {
        opacity: 0.8;
        fill: #14B8E1;
    }
    
    .iconCanvas-3 {
        width: 100px;
        height: 90px;
    }
    
    .iconCanvas-3:hover {
        cursor: pointer;
        color: white;
        border-bottom: 2px solid#14B8E1;
        box-shadow: inset 0px -96px 12px -3px rgb(20 184 225 / 10%);
    }
    
    .iconEventAct {
        width: 100px;
        height: 90px;
        cursor: pointer;
        color: white;
        border-bottom: 2px solid#14B8E1;
        box-shadow: inset 0px -96px 12px -3px rgb(20 184 225 / 10%);
    }
    
    .iconEventAct .eventCanvasSvg {
        fill: #ffffff !important;
    }
    
    .iconEventAct p {
        color: #ffffff !important;
    }
    /* Icon canvas 4 */
    
    .realEstateSvg {
        opacity: 0.8;
        fill: #14B8E1;
    }
    
    .iconCanvas-4 {
        width: 100px;
        height: 90px;
    }
    
    .iconCanvas-4:hover {
        cursor: pointer;
        color: white;
        border-bottom: 2px solid#14B8E1;
        box-shadow: inset 0px -96px 12px -3px rgb(20 184 225 / 10%);
    }
    
    .iconRealEstateAct {
        width: 100px;
        height: 90px;
        cursor: pointer;
        color: white;
        border-bottom: 2px solid#14B8E1;
        box-shadow: inset 0px -96px 12px -3px rgb(20 184 225 / 10%);
    }
    
    .iconRealEstateAct .realEstateSvg {
        fill: #ffffff !important;
    }
    
    .iconRealEstateAct p {
        color: #ffffff !important;
    }
    /* Icon canvas 5 */
    
    .CanvasHospitalitySvg {
        opacity: 0.8;
        fill: #14B8E1;
    }
    
    .iconCanvas-5 {
        width: 100px;
        height: 90px;
    }
    
    .iconCanvas-5:hover {
        cursor: pointer;
        color: white;
        border-bottom: 2px solid#14B8E1;
        box-shadow: inset 0px -96px 12px -3px rgb(20 184 225 / 10%);
    }
    
    .iconHospitalAct {
        width: 100px;
        height: 90px;
        cursor: pointer;
        color: white;
        border-bottom: 2px solid#14B8E1;
        box-shadow: inset 0px -96px 12px -3px rgb(20 184 225 / 10%);
    }
    
    .iconHospitalAct .CanvasHospitalitySvg {
        fill: #ffffff !important;
    }
    
    .iconHospitalAct p {
        color: #ffffff !important;
    }
    /* Icon canvas 6 */
    
    .CanvasBusinessSvg {
        opacity: 0.8;
        fill: #14B8E1;
    }
    
    .iconCanvas-6 {
        width: 100px;
        height: 90px;
    }
    
    .iconCanvas-6:hover {
        cursor: pointer;
        color: white;
        border-bottom: 2px solid#14B8E1;
        box-shadow: inset 0px -96px 12px -3px rgb(20 184 225 / 10%);
    }
    
    .iconBusinessAct {
        width: 100px;
        height: 90px;
        cursor: pointer;
        color: white;
        border-bottom: 2px solid#14B8E1;
        box-shadow: inset 0px -96px 12px -3px rgb(20 184 225 / 10%);
    }
    
    .iconBusinessAct .CanvasBusinessSvg {
        fill: #ffffff !important;
    }
    
    .iconBusinessAct p {
        color: #ffffff !important;
    }
    /* Icon canvas 7 */
    
    .canvasEducationSvg {
        opacity: 0.8;
        fill: #14B8E1;
    }
    
    .iconCanvas-7 {
        width: 100px;
        height: 90px;
    }
    
    .iconCanvas-7:hover {
        cursor: pointer;
        color: white;
        border-bottom: 2px solid#14B8E1;
        box-shadow: inset 0px -96px 12px -3px rgb(20 184 225 / 10%);
    }
    
    .iconEducationAct {
        width: 100px;
        height: 90px;
        cursor: pointer;
        color: white;
        border-bottom: 2px solid#14B8E1;
        box-shadow: inset 0px -96px 12px -3px rgb(20 184 225 / 10%);
    }
    
    .iconEducationAct .canvasEducationSvg {
        fill: #ffffff !important;
    }
    
    .iconEducationAct p {
        color: #ffffff !important;
    }
    /* Icon canvas 8 */
    
    .canvasRestaurantSvg {
        opacity: 0.8;
        fill: #14B8E1;
    }
    
    .iconCanvas-8 {
        width: 100px;
        height: 90px;
    }
    
    .iconCanvas-8:hover {
        cursor: pointer;
        color: white;
        border-bottom: 2px solid#14B8E1;
        box-shadow: inset 0px -96px 12px -3px rgb(20 184 225 / 10%);
    }
    
    .iconRestaurantAct {
        width: 100px;
        height: 90px;
        cursor: pointer;
        color: white;
        border-bottom: 2px solid#14B8E1;
        box-shadow: inset 0px -96px 12px -3px rgb(20 184 225 / 10%);
    }
    
    .iconRestaurantAct .canvasRestaurantSvg {
        fill: #ffffff !important;
    }
    
    .iconRestaurantAct p {
        color: #ffffff !important;
    }
    /* Icon canvas end */
    
    .textColor {
        color: #a4bac6;
    }
    
    @media screen and (max-width:1020px) {
        h2 {
            color: blue;
        }
        /* h1 is still red */
    }
    
    @media screen and (max-width:768px) {
        #canvasBox {
            width: 100% !important;
            height: 100vh !important;
            margin-top: 51px !important;
            background-color: #05142a;
            background-image: none;
            color: #000 !important;
        }
        .bd-highlight {
            display: flex!important;
            flex-wrap: wrap;
            text-align: center;
            margin: 0 0 0 0!important;
        }
        #hrMobile {
            display: none;
        }
        .canvasFooter {
            background-color: #09254E !important;
            width: 100% !important;
            height: 4px !important;
            margin-left: -17px;
            opacity: .25;
        }
    }
    
    @media (max-width: 425px) {
        #canvasBox {
            width: 100% !important;
            height: 100vh !important;
            margin-top: 51px !important;
            background-color: #05142a;
            background-image: none;
            color: #000 !important;
        }
        .bd-highlight {
            display: flex!important;
            flex-wrap: wrap;
            text-align: center;
            margin: 0 0 0 0!important;
        }
        #hrMobile {
            display: none;
        }
    }