.navbarLogo {
    width: 136.74px;
    margin-left: 33px;
}

.navbarBtn {
    background-color: transparent;
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-radius: 30px;
    font-size: 15px;
    width: 10%;
    color: #ffffff;
    padding: 6px;
    font-weight: 500;
}

.st0 {
    width: 20px !important;
    fill: #fff !important;
    padding-bottom: 5px !important;
}

.navbarBtn:hover .st0 {
    fill: #000 !important;
}

.navbarBtn:hover {
    background-color: #ffffff;
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-radius: 30px;
    font-size: 15px;
    width: 10%;
    color: #09254e;
    fill: #000 !important;
    padding: 6px;
    font-weight: 600;
}

.customFont {
    font-family: "SF Pro";
}

.bgText {
    background: url(../../static/images/bg1880@2x.jpg);
    background-size: contain;
    height: 70vh;
    width: 100%;
}

.bgBannerHeading {
    font-size: 5.125rem;
    font-weight: bold;
    color: #ffff;
}

.bgBannerText {
    font-size: 1.375rem;
    color: #ffff !important;
    text-align: center;
}

.nextToBannerHeading {
    color: #ffff;
    font-size: 3rem;
    font-weight: bold;
    word-spacing: 4px;
}

.nextToBannerHead {
    font-size: 23px;
    word-spacing: 4px;
}

.nextToBannerHeadPa {
    font-size: 20px;
    word-spacing: 2px;
    width: 42%!;
    color: #fff;
    margin: auto;
}

.bgColor {
    background-color: rgba(0, 0, 0, 0.6) !important;
    height: 70vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.btnBgImg {
    background-color: transparent !important;
    width: 176px;
    height: 43px;
    border-radius: 30px;
    color: #fff !important;
    margin-top: 12px;
    font-size: 17px;
    padding: 7px 22px;
    font-weight: 500;
    border: 1px solid white;
}

.btnBgImg:hover {
    background-color: #ffffff !important;
    width: 176px;
    height: 43px;
    border-radius: 30px;
    color: #000 !important;
    margin-top: 12px;
    font-size: 17px;
    padding: 7px 22px;
    font-weight: 500;
    border: 1px solid white;
}

#firstSvgImg {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}

.textHeight {
    line-height: 2 !important;
}

.backgroundIcon {
    background-image: url("../../static/svg/mail-inbox-app.svg");
    background-size: 32px;
    background-position: 98.5% center;
    position: absolute;
    top: 0;
    background-repeat: no-repeat;
}

.btnDropQuery {
    background-color: #ffffff!important;
    color: rgba(0, 0, 0) !important;
}

.btnDropQuery:not(:placeholder-shown) {
    background-color: #ffffff!important;
    color: #6c757d !important;
}

.btnDropQuery:placeholder-shown {
    background-color: rgba(255, 255, 255, 13%) !important;
    border: 1px solid #252F3D !important;
    padding-left: 10px !important;
    color: rgba(0, 0, 0, 0.664) !important;
}

.btnDropQuery:focus {
    background-color: #ffffffff !important;
    box-shadow: none !important;
    color: rgba(0, 0, 0) !important;
}

.btnDropQuery::placeholder {
    color: #6c757d !important;
}

textarea:not(:placeholder-shown) {
    background-color: #ffffff!important;
    color: black;
}

textarea:placeholder-shown {
    background-color: #707070 !important;
    border: 1px solid #707070 !important;
    color: rgba(163, 163, 163, 0.25) !important;
    padding-left: 10px !important;
}

#expBtn {
    background-color: #14B8E1 !important;
    font-size: 18px !important;
    font-weight: 500 !important;
    border: none;
    color: #000;
    width: 121px;
    height: 40px;
}

#expBtn:focus {
    background-color: #14B8E1 !important;
    box-shadow: 0 0 0 1px #14B8E1;
}

.bgImg_1 {
    background-image: url(../../static/images/landing_slider_1.jpg);
    background-size: -50% -50%;
    width: 100%;
    background-repeat: no-repeat;
}


/*footer start*/

.facebookIcon:hover {
    background-color: blue !important;
}

@media only screen and (max-width: 600px) {
    .navbarLogo {
        width: 117.74px;
        margin-left: 12px;
    }
    .navbarBtnContainer {
        background-color: transparent;
        border: 2px solid rgba(255, 255, 255, 0.3);
        border-radius: 30px;
        font-size: 15px;
        width: 100%;
        color: #ffffff;
        padding: 8px;
        font-weight: 500;
    }
    .navbarUserLogo {
        width: 27.91px;
        margin-right: -19px !important;
    }
    .navbar {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        padding-top: -0.5rem;
        padding-bottom: -0.5rem;
    }
    .bgText {
        background: url(../../static/images/bg1880@2x.jpg);
        background-size: contain;
        height: 293px;
        width: 100%;
    }
    .bgColor {
        background-color: rgba(0, 0, 0, 0.6);
        height: 293px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }
    .btnBgImg {
        background-color: transparent !important;
        width: 159px;
        height: 38px;
        border-radius: 30px;
        color: #ffff !important;
        margin-top: 12px;
        font-size: 15px;
        padding: 7px 22px;
        font-weight: 500;
        border: 1px solid white;
    }
    .btnBgImg:hover {
        background-color: #ffffff!important;
        width: 159px;
        height: 38px;
        border-radius: 30px;
        color: rgb(9 37 78) !important;
        margin-top: 12px;
        font-size: 15px;
        padding: 7px 22px;
        font-weight: 500;
        border: 1px solid white;
    }
    .bgBannerHeading {
        font-size: 26px;
        font-weight: bold;
        color: #ffffffff;
    }
    .bgBannerText {
        font-size: 15px;
        color: #ffffffff;
        position: relative;
        left: 17px;
        width: 93%;
        margin-top: 8px !important;
    }
    .nextToBannerHeading {
        color: #ffffff;
        font-size: 30px;
        font-weight: bold;
        word-spacing: 4px;
    }
    .nextToBannerHead {
        font-size: 16px;
        word-spacing: 4px;
        width: 84%;
        margin: auto;
    }
    .navbarBtn {
        background-color: transparent;
        border: 2px solid rgba(255, 255, 255, 0.3);
        border-radius: 30px;
        font-size: 13px;
        width: 43%;
        color: #ffffff;
        padding: 5px;
        font-weight: 500;
    }
    .st0 {
        width: 16px !important;
        fill: #fff !important;
        padding-bottom: 3px !important;
    }
    .navbarBtn:hover .st0 {
        fill: #000 !important;
    }
    .navbarBtn:hover {
        background-color: #ffffff;
        border: 2px solid rgba(255, 255, 255, 0.3);
        border-radius: 30px;
        font-size: 13px;
        width: 43%;
        color: #09254e;
        fill: #000 !important;
        padding: 5px;
        font-weight: 600;
    }
    .nextToBannerHeadPa {
        display: none;
    }
    .cardTwoP {
        font-size: 15px;
        line-height: 2;
        color: #fff;
        width: 50%;
        word-spacing: 2px;
    }
    .img-card2 {
        width: 50% !important;
        position: relative !important;
        top: -100%;
        left: 163px;
        bottom: 0;
    }
    .img-card3 {
        width: 37%;
        position: relative;
        top: -132px;
        left: 223px;
    }
    .imgMail {
        display: flex;
        justify-content: center;
        padding: 0 !important;
    }
    #mailBoxHead {
        font-size: 30px;
    }
    #mailBoxHead {
        font-size: 30px;
    }
    #mailBoxP {
        font-size: 14px;
        color: white;
    }
    .mobileContainer {
        padding: 0 !important;
    }
    .mobileContainer>.radiuss {
        border-radius: 0 !important;
    }
    #formBox {
        padding: 1rem !important;
        border: none !important;
    }
    #expBtn {
        background-color: #14B8E1 !important;
        font-size: 18px !important;
        font-weight: 200 !important;
        border: none;
        width: 100%;
        color: #000 !important;
        height: 40px;
    }
    #expBtn:focus {
        background-color: #14B8E1 !important;
        box-shadow: 0 0 0 1px #14B8E1;
    }
    #newsLetterHeading {
        font-size: 20px!important;
        font-weight: bold;
    }
    #newsLetterBtn {
        width: 85% !important;
    }
    #newsLetterTextHide {
        display: none;
        margin-top: 10px;
    }
    #boxMail::before {
        content: "Have you some question?";
        color: #fff;
        font-size: 22px;
        font-weight: 500;
    }
    #boxMail {
        position: relative;
        text-align: start;
        padding-left: 72px;
    }
    .threeSixtyTex {
        font-size: 29px !important;
        color: #ffffff;
        align-items: center;
        font-weight: bold;
    }
    .slide-one {
        width: 89% !important;
        margin: auto;
        top: 0px;
        border-radius: 7px;
    }
    .slide-two {
        width: 89% !important;
        margin: auto;
        top: 0px;
        border-radius: 7px;
    }
    .slide-three {
        width: 89% !important;
        margin: auto;
        top: 0px;
        border-radius: 7px;
    }
    .bd-highlight {
        display: block !important;
        text-align: center;
        padding-top: 6px !important;
        margin: 5px 0 0 0!important;
    }
    #sectionOne {
        width: 100% !important;
        text-align: center !important;
        margin: 19px 0 0 0!important;
    }
    #sectionTwo {
        width: 100% !important;
        display: flex !important;
        flex-direction: column;
        align-items: center;
        word-spacing: 1px;
        position: relative;
        top: -12px;
        padding-top: 3px !important;
    }
    .logoFooter {
        width: 69px !important;
        margin-top: 17px;
    }
    #hrDesktop {
        display: none;
        background-color: #09254E;
        width: 100%;
        margin: auto;
        height: 5px;
    }
    #iconFace {
        height: 36px;
    }
    #icon1 {
        width: 53px !important;
    }
    .marginMbTop {
        margin-top: 15px;
    }
    .textSliderCards {
        font-size: 15px !important;
        padding: 10px;
        text-align: start;
        width: 100%;
        background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(0, 0, 0, 50%));
    }
    .center360Icon {
        width: 36px;
    }
    .videIcon {
        width: 30px;
    }
}


/* Medium devices (landscape tablets, 600px and up) */

@media only screen and (min-width: 600px) and (orientation: landscape) {
    .bgText {
        background: url(../../static/images/bg1880@2x.jpg);
        background-size: contain;
        height: 71vh;
        width: 100%;
    }
    .bgColor {
        background-color: rgba(0, 0, 0, 0.6) !important;
        height: 71vh;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }
    .bgBannerHeading {
        font-size: 46px;
        font-weight: bold;
        color: #ffff;
    }
    .bgBannerHeading {
        font-size: 46px;
        font-weight: bold;
        color: #ffff;
    }
    .nextToBannerHeadPa {
        font-size: 16px;
        word-spacing: 2px;
        color: #fff;
        width: 80%;
        margin: auto;
    }
    .nextToBannerHeading {
        color: #ffff;
        font-size: 33px;
        font-weight: bold;
        word-spacing: 4px;
    }
    .nextToBannerHead {
        font-size: 21px;
        word-spacing: 2px;
    }
    .btnBgImg {
        background-color: transparent !important;
        width: 176px;
        height: 43px;
        border-radius: 30px;
        color: #ffff !important;
        margin-top: 12px;
        font-size: 17px;
        padding: 7px 22px;
        font-weight: 500;
        border: 1px solid white;
    }
    .btnBgImg:hover {
        background-color: #ffffff!important;
        width: 176px;
        height: 43px;
        border-radius: 30px;
        color: rgb(9 37 78) !important;
        margin-top: 12px;
        font-size: 17px;
        padding: 7px 22px;
        font-weight: 500;
        border: 1px solid white;
    }
    .navbarBtn {
        background-color: transparent;
        border: 2px solid rgba(255, 255, 255, 0.3);
        border-radius: 30px;
        font-size: 14px;
        width: 24%;
        color: #ffffff;
        padding: 4px;
        font-weight: 500;
    }
    .st0 {
        width: 16px !important;
        fill: #fff !important;
        padding-bottom: 3px !important;
    }
    .navbarBtn:hover .st0 {
        fill: #000 !important;
    }
    .navbarBtn:hover {
        background-color: #ffffff;
        border: 2px solid rgba(255, 255, 255, 0.3);
        border-radius: 30px;
        font-size: 14px;
        width: 24%;
        color: #09254e;
        fill: #000 !important;
        padding: 4px;
        font-weight: 600;
    }
    .threeSixtyTex {
        font-size: 2.525rem !important;
        color: #ffffff;
        align-items: center;
        font-weight: bold;
    }
    #mailBoxHead {
        font-size: 30px;
    }
    #mailBoxHead {
        font-size: 30px;
    }
    #mailBoxP {
        font-size: 14px;
        color: white;
    }
    #hrDesktop {
        display: none;
        background-color: #09254E;
        width: 38%;
        margin: auto;
        height: 5px;
    }
    #sectionOne {
        width: 22%;
        text-align: center;
        margin-left: 12px;
        padding-top: 0px;
    }
    #sectionTwo {
        width: 36%;
        text-align: center;
        padding: 1;
        margin-left: 5px;
    }
    #icon1 {
        width: 32px;
    }
    .textSliderCards {
        font-size: 15px;
        padding: 10px;
        text-align: start;
        width: 100%;
        background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(0, 0, 0, 50%));
    }
    .center360Icon {
        width: 36px;
    }
    .videIcon {
        width: 30px;
    }
}


/* Large devices (laptops/desktops, 992px and up) */

@media only screen and (min-width: 768px) {
    .bgText {
        background: url(../../static/images/bg1880@2x.jpg);
        background-size: contain;
        height: 65vh;
        width: 100%;
    }
    .bgColor {
        background-color: rgba(0, 0, 0, 0.6) !important;
        height: 65vh;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }
    .bgBannerHeading {
        font-size: 38px;
        font-weight: bold;
        color: #ffff;
    }
    .nextToBannerHeadPa {
        font-size: 16px;
        word-spacing: 2px;
        color: #fff;
        width: 80%;
        margin: auto;
    }
    .nextToBannerHeading {
        color: #ffff;
        font-size: 33px;
        font-weight: bold;
        word-spacing: 4px;
    }
    .nextToBannerHead {
        font-size: 21px;
        word-spacing: 2px;
    }
    .btnBgImg {
        background-color: transparent !important;
        width: 176px;
        height: 43px;
        border-radius: 30px;
        color: #ffff !important;
        margin-top: 12px;
        font-size: 17px;
        padding: 7px 22px;
        font-weight: 500;
        border: 1px solid white;
    }
    .btnBgImg:hover {
        background-color: #ffffff!important;
        width: 176px;
        height: 43px;
        border-radius: 30px;
        color: rgb(9 37 78) !important;
        margin-top: 12px;
        font-size: 17px;
        padding: 7px 22px;
        font-weight: 500;
        border: 1px solid white;
    }
    .navbarBtn {
        background-color: transparent;
        border: 2px solid rgba(255, 255, 255, 0.3);
        border-radius: 30px;
        font-size: 13px;
        width: 21%;
        color: #ffffff;
        padding: 6px;
        font-weight: 500;
    }
    .st0 {
        width: 16px !important;
        fill: #fff !important;
        padding-bottom: 3px !important;
    }
    .navbarBtn:hover .st0 {
        fill: #000 !important;
    }
    .navbarBtn:hover {
        background-color: #ffffff;
        border: 2px solid rgba(255, 255, 255, 0.3);
        border-radius: 30px;
        font-size: 13px;
        width: 21%;
        color: #09254e;
        fill: #000 !important;
        padding: 6px;
        font-weight: 600;
    }
    .threeSixtyTex {
        font-size: 2.525rem !important;
        color: #ffffff;
        align-items: center;
        font-weight: bold;
    }
    #mailBoxHead {
        font-size: 30px;
    }
    #mailBoxHead {
        font-size: 30px;
    }
    #mailBoxP {
        font-size: 14px;
        color: white;
    }
    #hrDesktop {
        display: none;
        background-color: #09254E;
        width: 38%;
        margin: auto;
        height: 5px;
    }
    #sectionOne {
        width: 22%;
        text-align: center;
        margin-left: 12px;
        padding-top: 0px;
    }
    #sectionTwo {
        width: 36%;
        text-align: center;
        padding: 1;
        margin-left: 5px;
    }
    #icon1 {
        width: 32px;
    }
    .textSliderCards {
        font-size: 15px;
        padding: 10px;
        text-align: start;
        width: 100%;
        background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(0, 0, 0, 50%));
    }
    .center360Icon {
        width: 36px;
    }
    .videIcon {
        width: 30px;
    }
}

@media only screen and (min-width: 992px) {
    .bgText {
        background: url(../../static/images/bg1880@2x.jpg);
        background-size: 100% 100%;
        height: 41vh;
        width: 100%;
    }
    .bgColor {
        background-color: rgba(0, 0, 0, 0.6) !important;
        height: 41vh;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }
    .bgBannerHeading {
        font-size: 4.125rem;
        font-weight: bold;
        color: #ffff;
    }
    .bgBannerText {
        font-size: 22px;
        color: #ffff;
        position: relative;
        left: 17px;
        margin: auto;
        width: 43%;
    }
    #boxMail {
        position: relative;
        text-align: start;
        padding-left: 72px;
    }
    .btnBgImg {
        background-color: transparent !important;
        width: 176px;
        height: 43px;
        border-radius: 30px;
        color: #fff !important;
        margin-top: 12px;
        font-size: 17px;
        padding: 7px 22px;
        font-weight: 500;
        border: 1px solid white;
    }
    .btnBgImg:hover {
        background-color: #ffffff !important;
        width: 176px;
        height: 43px;
        border-radius: 30px;
        color: rgb(9 37 78) !important;
        margin-top: 12px;
        font-size: 17px;
        padding: 7px 22px;
        font-weight: 500;
        border: 1px solid white;
    }
    .navbarBtn {
        background-color: transparent;
        border: 2px solid rgba(255, 255, 255, 0.3);
        border-radius: 30px;
        font-size: 14px;
        width: 19%;
        color: #ffffff;
        padding: 6px;
        font-weight: 500;
    }
    .st0 {
        width: 16px !important;
        fill: #fff !important;
        padding-bottom: 3px !important;
    }
    .navbarBtn:hover .st0 {
        fill: #000 !important;
    }
    .navbarBtn:hover {
        background-color: #ffffff;
        border: 2px solid rgba(255, 255, 255, 0.3);
        border-radius: 30px;
        font-size: 14px;
        width: 19%;
        color: #09254e;
        fill: #000 !important;
        padding: 6px;
        font-weight: 600;
    }
    .nextToBannerHeading {
        color: #ffff;
        font-size: 40px;
        font-weight: bold;
        word-spacing: 3px;
    }
    .nextToBannerHead {
        font-size: 35px;
        word-spacing: 4px;
        width: 66%;
        margin: auto;
    }
    .nextToBannerHeadPa {
        font-size: 22px;
        word-spacing: 2px;
        width: 77%;
        color: #fff;
        margin: auto;
    }
    .dNone-sm {
        display: none;
    }
    br {
        display: none;
    }
    .threeSixtyTex {
        font-size: 3.125rem !important;
        color: #ffffff;
        align-items: center;
        font-weight: bold;
    }
    .cardTwoP {
        font-size: 15px;
        line-height: 2;
        color: #fff;
        width: 50%;
        word-spacing: 2px;
    }
    .img-card2 {
        width: 50% !important;
        position: relative !important;
        top: -100%;
        left: 163px;
        bottom: 0;
    }
    .img-card3 {
        width: 37%;
        position: relative;
        top: -132px;
        left: 223px;
    }
    .imgMail {
        display: flex;
        justify-content: center;
        padding: 0 !important;
    }
    #mailBoxHead {
        font-size: 30px;
    }
    #mailBoxHead {
        font-size: 30px;
    }
    #mailBoxP {
        font-size: 14px;
        color: white;
    }
    #newsLetterHeading {
        font-size: 28px!important;
    }
    #newsLetterBtn {
        width: 50% !important;
    }
    .slide-one {
        width: 89% !important;
        margin: auto;
        top: 0px;
        border-radius: 9px !important;
    }
    .slide-two {
        width: 89% !important;
        margin: auto;
        top: 0px;
        border-radius: 9px;
    }
    .slide-three {
        width: 89% !important;
        margin: auto;
        top: 0px;
        border-radius: 9px;
    }
    #hrDesktop {
        display: inline-block;
        background-color: #09254E;
        width: 5% !important;
        margin: auto;
        height: 5px;
    }
    #sectionOne {
        width: 17% !important;
        text-align: center !important;
        margin-left: 10px!important;
        padding-top: 0px;
    }
    #sectionTwo {
        width: 35% !important;
        text-align: center;
        padding: 1;
    }
    #icon1 {
        width: 32px !important;
    }
    /* .firstNameField {
            position: relative;
            left: 52px !important;
        } */
    .firstName {
        position: relative !important;
        width: 90% !important;
        /* right: 7px !important; */
    }
    .lastName {
        position: relative !important;
        width: 90% !important;
        right: 12px !important;
    }
    .textSliderCards {
        font-size: 15px !important;
        padding: 10px;
        text-align: start;
        width: 100%;
        background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(0, 0, 0, 50%));
    }
    .center360Icon {
        width: 36px;
    }
    .videIcon {
        width: 30px;
    }
}


/* Extra large devices (large laptops and desktops, 1200px and up) */

@media only screen and (min-width: 1200px) {
    .bgText {
        background: url(../../static/images/bg1880@2x.jpg);
        background-size: 100% 88%;
        height: 60vh;
        width: 100%;
    }
    .bgColor {
        background-color: rgba(0, 0, 0, 0.6) !important;
        height: 60vh;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }
    .bgBannerHeading {
        font-size: 5.125rem;
        font-weight: bold;
        color: #ffff;
    }
    .bgBannerText {
        font-size: 22px;
        color: #ffff;
        position: relative;
        left: 17px;
        margin: auto;
        width: 40%;
    }
    .nextToBannerHeading {
        color: #ffff;
        font-size: 57px;
        font-weight: bold;
        word-spacing: 4px;
    }
    .nextToBannerHead {
        font-size: 28px;
        word-spacing: 4px;
        width: 50%;
        margin: auto;
    }
    .nextToBannerHeadPa {
        font-size: 20px;
        word-spacing: 2px;
        width: 70%;
        color: #fff;
        margin: auto;
    }
    .navbarBtn {
        background-color: transparent;
        border: 2px solid rgba(255, 255, 255, 0.3);
        border-radius: 30px;
        font-size: 14px;
        width: 13%;
        color: #ffffff;
        padding: 5px;
        font-weight: 500;
    }
    .st0 {
        width: 16px !important;
        fill: #fff !important;
        padding-bottom: 3px !important;
    }
    .navbarBtn:hover .st0 {
        fill: #000 !important;
    }
    .navbarBtn:hover {
        background-color: #ffffff;
        border: 2px solid rgba(255, 255, 255, 0.3);
        border-radius: 30px;
        font-size: 14px;
        width: 13%;
        color: #09254e;
        fill: #000 !important;
        padding: 5px;
        font-weight: 600;
    }
    .dNone-sm {
        display: none;
    }
    br {
        display: block;
    }
    .threeSixtyTex {
        font-size: 57px !important;
        color: #ffffff;
        align-items: center;
        font-weight: bold;
    }
    .cardTwoP {
        font-size: 15px;
        line-height: 2;
        color: #fff;
        width: 50%;
        word-spacing: 2px;
    }
    .img-card2 {
        width: 50% !important;
        position: relative !important;
        top: -100%;
        left: 163px;
        bottom: 0;
    }
    .img-card3 {
        width: 37%;
        position: relative;
        top: -132px;
        left: 223px;
    }
    .dropQformIcon {
        width: 68%;
    }
    .imgMail {
        display: flex;
        justify-content: start;
        padding-left: 182px !important;
        margin-left: 22px !important;
    }
    #mailBoxHead {
        font-size: 30px;
    }
    #mailBoxHead {
        font-size: 57px;
    }
    #mailBoxP {
        font-size: 22px;
        color: white;
        margin-right: 64px;
    }
    #boxMail {
        position: relative;
        text-align: start;
        padding-left: 72px;
    }
    #newsLetterHeading {
        font-size: 28px!important;
        font-weight: bold;
        padding-top: 40px;
    }
    .logoFooter {
        width: 40px;
        margin-top: 3px;
        margin-left: 16px !important;
    }
    #newsLetterBtn {
        width: 32% !important;
        height: 46px;
        /* margin-bottom: 57px; */
    }
    .newsLetterBox {
        width: 100%;
        margin-bottom: 61px !important;
    }
    .slide-one {
        width: 100% !important;
        margin: auto;
        top: 0px;
        border-radius: 9px !important;
    }
    .slide-two {
        width: 100% !important;
        margin: auto;
        top: 0px;
        border-radius: 9px !important;
    }
    .slide-three {
        width: 100% !important;
        margin: auto;
        top: 0px;
        border-radius: 7px !important;
    }
    #formBox {
        border-bottom-right-radius: 9px;
        border-top-right-radius: 9px;
    }
    #hrDesktop {
        display: inline-block;
        background-color: transparent;
        width: 68% !important;
        margin: auto;
        height: 5px;
    }
    #sectionOne {
        width: 16% !important;
        text-align: center !important;
        margin-left: 1px!important;
        padding-top: 0px;
    }
    #sectionTwo {
        width: 31% !important;
        text-align: center;
        padding: 1;
        margin-left: 5px !important;
    }
    #icon1 {
        width: 32px !important;
    }
    #newsLetterTextHide {
        font-weight: bold;
    }
    .textSliderCards {
        font-size: 22px !important;
        font-weight: bold;
        padding: 22px;
        text-align: start;
        border-radius: 7px;
        width: 100%;
        background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(0, 0, 0, 50%));
    }
    .firstNameField {
        position: relative;
        left: 52px !important;
    }
    .center360Icon {
        width: 48px;
    }
    .videIcon {
        width: 36px;
    }
}

@media only screen and (min-width: 1824px) {
    .nextToBannerHeadPa {
        font-size: 20px;
        word-spacing: 2px;
        width: 42%;
        color: #fff;
        margin: auto;
    }
    .navbarBtn {
        background-color: transparent;
        border: 2px solid rgba(255, 255, 255, 0.3);
        border-radius: 30px;
        font-size: 14px;
        width: 9%;
        color: #ffffff;
        padding: 6px;
        font-weight: 500;
    }
    .st0 {
        width: 16px !important;
        fill: #fff !important;
        padding-bottom: 3px !important;
    }
    .navbarBtn:hover .st0 {
        fill: #000 !important;
    }
    .navbarBtn:hover {
        background-color: #ffffff;
        border: 2px solid rgba(255, 255, 255, 0.3);
        border-radius: 30px;
        font-size: 14px;
        width: 9%;
        color: #09254e;
        fill: #000 !important;
        padding: 6px;
        font-weight: 600;
    }
}