/* Animation */

.card__IconBg {
    margin: 0;
    opacity: 0;
    display: inline-block;
    overflow: visible;
    position: relative;
}

.cardsvg__icon {
    width: 100%;
    height: 250px;
    min-width: 100%;
    min-height: 100%;
    border-radius: 9px;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 15%);
}

.cardHeadingName {
    color: #FFFFFF;
    width: 100%;
    height: 100%;
    /* bottom: 0px; */
    /* z-index: 1; */
    /* padding: 17px 22px; */
    /* position: absolute; */
    font-size: 18px;
    text-align: center;
    font-weight: 600;
    text-shadow: 0px 4px 6px #494a4f;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgb(0 0 0 / 44%));
    text-transform: capitalize;
}

.cardHeadingFontSize {
    font-size: 22px;
    font-weight: 500;
}

.backgroundImage {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    -webkit-transition: all 0.7s ease;
    transition: all 0.7s ease;
    /* border-radius: 9px; */
}

.backgroundImage:hover {
    transform: scale(1.1) !important;
    /* border-radius: 9px; */
}

.likeSvg {
    overflow: visible;
    animation: pulse 1.5s cubic-bezier(0.68, -0.55, 0.27, 1.55) 1s infinite;
    fill: #FE4C67;
}

.likeSvgClick {
    overflow: visible;
    animation: pulse 1.5s cubic-bezier(0.68, -0.55, 0.27, 1.55) 1s infinite;
    stroke: #fffbfb;
    fill: transparent;
}

.imgZo {
    overflow: hidden;
    padding: 0;
    width: 300px;
    height: 300px;
    float: left;
    margin: 10px;
}

.imgZo .zoom {
    transition-duration: 4s;
    margin: 0 auto;
    display: block;
}

.imgZo .zoom:hover {
    transform: scale(1.2);
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    z-index: 0;
}

.flexpanel-item-college {
    background-color: black;
    height: auto;
    margin: .5rem;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex: 30% 1;
    border-radius: 6px;
}

.explore-flexpanel {
    position: relative;
}

.flexpanel-section {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
}

.flexpanel-item {
    background-color: black;
    height: 25rem;
    margin: .5rem;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    -ms-flex: 30%;
    border-radius: 5px;
    flex: 30%;
}

.signup-flexpanel .flexpanel-item:nth-child(5n+1),
.explore-flexpanel .flexpanel-item:nth-child(5n+1),
.signup-flexpanel .flexpanel-item:nth-child(5n+2),
.explore-flexpanel .flexpanel-item:nth-child(5n+2),
.signup-flexpanel .flexpanel-item:nth-child(5n+3),
.explore-flexpanel .flexpanel-item:nth-child(5n+3) {
    -ms-flex: 30%;
    flex: 30%;
}

.signup-flexpanel .flexpanel-item:nth-child(5n+4),
.explore-flexpanel .flexpanel-item:nth-child(5n+4),
.signup-flexpanel .flexpanel-item:nth-child(5n+5),
.explore-flexpanel .flexpanel-item:nth-child(5n+5) {
    -ms-flex: 40%;
    flex: 40%;
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
    .signup-flexpanel .flexpanel-item:nth-child(4n+1),
    .explore-flexpanel .flexpanel-item:nth-child(4n+1),
    .signup-flexpanel .flexpanel-item:nth-child(4n+4),
    .explore-flexpanel .flexpanel-item:nth-child(4n+4) {
        -ms-flex: 40%;
        flex: 40%;
    }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
    .signup-flexpanel .flexpanel-item:nth-child(4n+2),
    .explore-flexpanel .flexpanel-item:nth-child(4n+2),
    .signup-flexpanel .flexpanel-item:nth-child(4n+3),
    .explore-flexpanel .flexpanel-item:nth-child(4n+3) {
        -ms-flex: 56%;
        flex: 56%;
    }
}

@media only screen and (max-width: 768px) {
    .flexpanel-item {
        -ms-flex: 100% !important;
        flex: 100% !important;
    }
}


/* css for feature  */

@media only screen and (max-width: 480px) {
    .flexpanel-item-college {
        -ms-flex: 100% !important;
        flex: 100% !important;
    }
}

.signup-flexpanel .flexpanel-item-college:nth-child(5n+1),
.explore-flexpanel .flexpanel-item-college:nth-child(5n+1),
.signup-flexpanel .flexpanel-item-college:nth-child(5n+2),
.explore-flexpanel .flexpanel-item-college:nth-child(5n+2),
.signup-flexpanel .flexpanel-item-college:nth-child(5n+3),
.explore-flexpanel .flexpanel-item-college:nth-child(5n+3) {
    -ms-flex: 30%;
    flex: 30%;
}

.signup-flexpanel .flexpanel-item-college:nth-child(5n+4),
.explore-flexpanel .flexpanel-item-college:nth-child(5n+4),
.signup-flexpanel .flexpanel-item-college:nth-child(5n+5),
.explore-flexpanel .flexpanel-item-college:nth-child(5n+5) {
    -ms-flex: 40%;
    flex: 40%;
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
    .signup-flexpanel .flexpanel-item-college:nth-child(4n+1),
    .explore-flexpanel .flexpanel-item-college:nth-child(4n+1),
    .signup-flexpanel .flexpanel-item-college:nth-child(4n+4),
    .explore-flexpanel .flexpanel-item-college:nth-child(4n+4) {
        -ms-flex: 40%;
        flex: 40%;
    }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
    .signup-flexpanel .flexpanel-item-college:nth-child(4n+2),
    .explore-flexpanel .flexpanel-item-college:nth-child(4n+2),
    .signup-flexpanel .flexpanel-item-college:nth-child(4n+3),
    .explore-flexpanel .flexpanel-item-college:nth-child(4n+3) {
        -ms-flex: 56%;
        flex: 56%;
    }
}

@media screen and (min-width: 481px) and (max-width: 767px) {
    .signup-flexpanel .flexpanel-item-college:nth-child(3n+1),
    .explore-flexpanel .flexpanel-item-college:nth-child(3n+1),
    .signup-flexpanel .flexpanel-item-college:nth-child(3n+2),
    .explore-flexpanel .flexpanel-item-college:nth-child(3n+2) {
        -ms-flex: 50%;
        flex: 50%;
    }
}

@media screen and (min-width: 481px) and (max-width: 768px) {
    .signup-flexpanel .flexpanel-item-college:nth-child(3n+3),
    .explore-flexpanel .flexpanel-item-college:nth-child(3n+3) {
        -ms-flex: 100%;
        flex: 100%;
    }
}