        .navbarTourLogo {
            width: 150.74px;
            padding-left: .5rem;
            /* padding-bottom: .5rem; */
        }
        
        .navbarTourUserIcon {
            width: 39.91px;
        }
        
        .headH1 {
            font-size: 3.4rem;
            font-weight: bold;
        }
        
        .headP {
            color: #14b8e1;
            font-size: 17px;
        }
        
        #toggleBtn {
            background-color: #09254e !important;
            border: none !important;
            color: #ffffff !important;
            box-shadow: none !important;
        }
        
        #toggleBtn span {
            background-color: #09254e !important;
            border: none !important;
            color: #ffffff !important;
            box-shadow: none !important;
        }
        
        .custom-toggler .navbar-toggler-icon {
            background-image: url("../../static/svg/toggleIcon.svg") !important;
        }
        
        .navbar-light .navbar-toggler-icon {
            background-image: url("../../static/svg/toggleIcon.svg") !important;
        }
        
        .custom-toggler.navbar-toggler {
            border-color: rgb(255, 102, 203);
        }
        
        #searchForm {
            width: 50%;
            height: 40px;
        }
        
        .headSearchInputField {
            padding: 0.375rem 0.75rem !important;
            font-size: 15px !important;
            font-weight: 400;
            line-height: 1.5;
            color: #a4bac6 !important;
            background-color: #040f1f !important;
            background-clip: padding-box;
            /* border: 1px solid #ced4da; */
            -webkit-appearance: none;
            appearance: none;
            border: none !important;
            border-radius: 2px;
        }
        
        .form-check-input:checked[type=checkbox] {
            background-image: url("../../static/svg/checkboxTick.svg");
            background-size: 12px;
        }
        
        .headSearchInputField:focus {
            padding: 0.375rem 0.75rem !important;
            font-size: 15px !important;
            font-weight: 400;
            line-height: 1.5;
            color: #a4bac6 !important;
            background-color: #040f1f !important;
            background-clip: padding-box;
            /* border: 1px solid #ced4da; */
            box-shadow: none !important;
            -webkit-appearance: none;
            appearance: none;
            border-radius: 0.25rem;
        }
        
        .searchIconField {
            background-color: #040f1f !important;
            border: none !important;
        }
        /* Filter Dropdown */
        
        #filterDropdown {
            background-color: #05142a !important;
            padding: 0px !important;
            padding: 1rem 0rem 0rem 1rem !important;
            width: 41.5rem;
            position: absolute !important;
            inset: 0px 0px auto auto !important;
            transform: translate(-19px, 46px) !important;
            border-radius: 12px;
        }
        
        #inputFieldFilterDesk {
            color: #A4BAC6 !important;
            height: 37px;
            background: #05142a url("../../static/svg/selectArrow.svg") no-repeat right .75rem center/16px 10px !important;
            font-size: 15px;
            border: 1px solid #546472;
            box-shadow: none;
        }
        
        #inputFieldFilterDesk {
            background-color: #05142a;
            color: #546472;
            height: 37px;
            background-image: url("../../static/svg/selectArrow.svg") !important;
            font-size: 15px;
            border: 1px solid #546472;
            box-shadow: none;
        }
        
        .dropdownBtn {
            /* display: none; */
            background-color: transparent !important;
            padding: 0px !important;
            box-shadow: none !important;
            outline: none !important;
            padding: 0.5rem 0rem 0rem 0.5rem !important;
        }
        
        .dropdownBtn:hover {
            /* display: none; */
            background-color: #093981 !important;
            padding: 0px !important;
            box-shadow: none !important;
            outline: none !important;
            padding: 0.5rem 0rem 0rem 0.5rem !important;
        }
        
        .btnCheckbox {
            font-size: 12px !important;
            align-items: center !important;
            left: 4px !important;
            outline: none !important;
            box-shadow: none !important;
            margin: 3px 3px !important;
            padding: auto !important;
            border-radius: 4px !important;
        }
        
        .activeBusinessBtn {
            background-color: #14b8e1;
            border: 1px solid #546472;
            color: #546472;
        }
        
        .notActiveBusinessBtn {
            background-color: #040f1f;
            border: 1px solid #546472;
            color: #546472;
        }
        /* filter Desktop view start*/
        
        #mobileFilterDesktopDropdown {
            background-color: #05142a !important;
            padding: 0px !important;
            padding: 1px !important;
            position: absolute !important;
            right: 80px;
            inset: 0px 0px auto auto !important;
            z-index: 2;
            width: 44rem !important;
            margin: auto !important;
            border-radius: 9px;
            transform: translate(736px, 48px) !important;
        }
        
        .filterSearchBox {
            background-color: #09254e;
            height: 60px;
            padding-top: 6px;
            border-top: 1px solid black;
        }
        
        .dropdown-toggle::after {
            display: none;
        }
        
        #seaarchFiled {
            position: relative;
            display: flex;
            flex-wrap: wrap;
            align-items: stretch;
            width: 92% !important;
            margin: auto !important;
        }
        
        .selectCityDesk {
            width: 85%;
            margin-bottom: 12px;
            font-size: 15px;
        }
        
        .react-calendar__tile:disabled {
            background-color: #f0f0f0;
            display: none;
        }
        
        .headMSearchInputField {
            padding: 0.375rem 0.75rem !important;
            font-size: 15px !important;
            font-weight: 400;
            line-height: 1.5;
            color: #a4bac6 !important;
            background-color: #040f1f !important;
            background-clip: padding-box;
            /* border: 1px solid #ced4da; */
            -webkit-appearance: none;
            appearance: none;
            border: none !important;
            border-top-left-radius: 10px !important;
            border-bottom-left-radius: 10px !important;
        }
        
        .searchMIconField {
            display: flex;
            align-items: center;
            padding: 0.375rem 0.75rem;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5 !important;
            color: #212529;
            text-align: center;
            white-space: nowrap;
            background-color: #040f1f !important;
            border: none !important;
            border-top-right-radius: 10px !important;
            border-bottom-right-radius: 10px !important;
        }
        
        .dropdownSearchBtn {
            box-shadow: none !important;
        }
        
        .dropdownSearchBtn:focus {
            box-shadow: none !important;
        }
        
        .checkBoxFilterDesktop label {
            margin-left: 7px !important;
            margin-top: 4px !important;
        }
        
        .checkBoxFilterDesktop>.form-check-input {
            width: 1.3em;
            height: 1.3em;
            margin-top: .25em;
            vertical-align: top;
            background-color: transparent;
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            border: 1px solid #546472;
            -webkit-appearance: none;
            appearance: none;
            -webkit-print-color-adjust: exact;
            color-adjust: exact;
        }
        
        .checkBoxFilterDesktop>.form-check-input:checked {
            background-color: #14B8E1 !important;
            border-color: #000000 !important;
        }
        
        .form-check-input:focus {
            /* border-color: #86b7fe; */
            outline: 0;
            box-shadow: none !important;
        }
        
        .react-calendar__tile {
            flex-basis: 14.2857% !important;
            max-width: 14.2857% !important;
        }
        
        #calendarStyle {
            width: 350px;
            max-width: 100%;
            background: #05142a !important;
            border: none !important;
            font-family: Arial, Helvetica, sans-serif;
            line-height: 1.125em;
        }
        
        .react-calendar__month-view__weekdays {
            text-align: center;
            text-transform: uppercase !important;
            font-weight: bold;
            color: #A4BAC6 !important;
            font-size: 14px !important;
        }
        
        .react-calendar__tile--now {
            background: transparent !important;
        }
        
        .btnFilterDesk {
            background-color: #14B8E1 !important;
            height: 33px !important;
            font-size: 14px !important;
            font-weight: 600;
            color: #07162c !important;
        }
        
        .backgroundSearch {
            background-image: url("../../static/svg/searchIcon.svg");
            background-size: 32px;
            background-color: #040f1f;
            border: none !important;
            background-position: 98.5% center;
            position: absolute;
            top: 0;
            color: #ffffff;
            background-repeat: no-repeat;
        }
        
        .backgroundSearch:focus {
            background-color: #040f1f;
            border: none !important;
            color: #ffffff !important;
            box-shadow: none !important;
        }
        /* Toggle Button Icons */
        
        @media (min-width: 320px) and (max-width: 480px) {
            .navbarTourLogo {
                width: 114.74px;
                padding-left: .5rem;
                /* padding-bottom: .5rem */
            }
            .navbarTourUserIcon {
                width: 28.91px;
            }
            .navbarStyle {
                margin: 0px !important;
                padding-top: 0px !important;
                padding-bottom: 0px !important;
            }
            #canvasBox {
                width: 100% !important;
                height: 100vh !important;
                margin-top: 51px !important;
                background-color: #05142a;
                background-image: none;
                color: #000 !important;
            }
            .bd-highlight {
                display: block !important;
                flex-wrap: wrap;
                text-align: center;
                padding-top: 6px !important;
                /* margin: 10px 0 0 0 !important; */
            }
            .headH1 {
                font-size: 32px;
                width: 90%;
                padding-left: 53px;
                text-align: center;
                font-weight: bold;
                line-height: 1.1
            }
            .headerText {
                margin: 14px !important;
            }
            .headP {
                color: #14b8e1;
                font-size: 15px;
                line-height: 3;
                margin-bottom: 1px !important;
            }
            .footerContainer {
                margin-bottom: 32px;
            }
        }
        
        @media screen and (max-width: 768px) {
            .navbarTourLogo {
                width: 114.74px;
                padding-left: .5rem;
                /* padding-bottom: .5rem */
            }
            .navbarTourUserIcon {
                width: 28.91px;
                margin-right: 8px;
            }
            .navbarStyle {
                margin: 0px !important;
                padding-top: 0px !important;
                padding-bottom: 0px !important;
            }
            .headMSearchInputField {
                padding: 0.375rem 0.75rem !important;
                font-size: 15px !important;
                font-weight: 400;
                line-height: 1.5;
                height: 33px;
                color: #a4bac6 !important;
                background-color: #040f1f !important;
                background-clip: padding-box;
                /* border: 1px solid #ced4da; */
                -webkit-appearance: none;
                appearance: none;
                border: none !important;
                border-top-left-radius: 10px !important;
                border-bottom-left-radius: 10px !important;
            }
            .headMSearchInputField:focus {
                box-shadow: none !important;
            }
            .filterSearchBox {
                background-color: #09254e;
                height: 53px;
                padding-top: 6px;
                border-top: 1px solid black;
            }
            .searchMIconField {
                display: flex;
                align-items: center;
                padding: .375rem .75rem;
                font-size: 1rem;
                font-weight: 400;
                height: 33px;
                line-height: 1.5 !important;
                color: #212529;
                margin-top: 4px !important;
                text-align: center;
                white-space: nowrap;
                background-color: #040F1F!important;
                border: none !important;
                border-top-right-radius: 10px !important;
                border-bottom-right-radius: 10px !important;
            }
            .headH1 {
                font-size: 28px;
                font-weight: bold;
            }
            .headerText {
                margin-top: 1rem !important;
                margin-bottom: 0px !important;
            }
            .navbar-light .navbar-toggler-icon {
                background-image: url("../../static/svg/toggleIcon.svg") !important;
                background-size: 76% !important;
            }
            #seaarchFiled {
                position: relative;
                display: flex;
                flex-wrap: wrap;
                align-items: stretch;
                width: 94% !important;
                margin: auto !important;
            }
        }