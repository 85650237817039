#hrDesktop {
    display: inline-block;
    background-color: transparent;
    width: 60%;
    margin: auto;
    height: 5px;
}

@media only screen and (max-width:768px) {
    #hrDesktop {
        display: inline-block;
        background-color: transparent;
        width: 38%;
        margin: auto;
        height: 5px;
    }
}

@media only screen and (max-width:480px) {
    #hrDesktop {
        display: none;
    }
}

@media only screen and (max-width:768px) {
    #sectionOne {
        width: 40% !important;
        text-align: center !important;
        margin: 19px 0 0 0!important;
    }
    #sectionTwo {
        width: 20%;
        display: block;
        flex-direction: column;
        align-items: center;
        word-spacing: 1px;
        position: relative;
        top: -12px;
        /* margin-bottom: 26px !important; */
        padding-top: 3px !important;
    }
    .logoFooter {
        width: 69px !important;
        margin-top: 17px;
    }
    #hrDesktop {
        display: inline-block;
        background-color: #09254E;
        width: 38%;
        margin: auto;
        height: 5px;
    }
    #iconFace {
        height: 36px !important;
    }
    #icon1 {
        width: 53px !important;
    }
}