    .btnTogglle {
        display: inline-block;
        font-weight: 400;
        line-height: 1.5;
        color: #212529;
        text-align: center;
        text-decoration: none;
        vertical-align: middle;
        cursor: pointer;
        -webkit-user-select: none;
        user-select: none;
        background-color: transparent;
        border: none;
        padding: 0;
        font-size: 1rem;
        border-radius: 0;
        transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    }
    
    .btnTogglle:focus {
        box-shadow: none !important;
        outline: none !important;
        border: none !important;
        background-color: transparent !important;
    }
    
    .btnTogglle:hover {
        outline: none !important;
        background-color: transparent !important;
    }
    
    .show>.btn-primary.dropdown-toggle {
        color: #fff;
        background-color: transparent;
        border-color: #0a53be;
    }
    
    .cardBody {
        background-color: #1f4a88;
        border: none;
        color: #ffff;
        padding: 5px 3px;
    }