.textSize {
    font-size: 15px !important;
    font-weight: 600 !important;
    width: '25em' !important;
}

.firstName {
    width: 90% !important;
}

.lastName {
    width: 90% !important;
    left: 21px !important;
}

.inputText {
    font-size: 19px !important;
    height: 50px !important;
    border-radius: 9px !important;
    color: #FFFFFF !important;
    border: 1px solid #252F3D !important;
}

.firstNameField {
    position: relative;
    left: 52px !important;
}

.inputLastNameText {
    font-size: 15px !important;
    height: 44px !important;
    border-radius: 9px !important;
    color: #000 !important;
    width: 95%;
    margin-left: 5px;
    border: 1px solid #252F3D !important;
}

.inputLastNameText:focus {
    font-size: 15px !important;
    height: 47px !important;
    border-radius: 9px !important;
    color: #FFFFFF !important;
    border: 1px solid #252F3D !important;
}

.inputLastNameText::placeholder {
    font-size: 14px !important;
    color: #FFFFFF !important;
    border: none !important;
}

.inputLastNameText:not(:placeholder-shown) {
    background-color: #ffffff!important;
    color: #000 !important;
}

.inputLastNameText:placeholder-shown {
    background-color: #252F3D !important;
    border: 1px solid #252F3D !important;
    padding-left: 10px !important;
    color: #ffffff !important;
}

.inputText:focus {
    font-size: 15px !important;
    height: 44px !important;
    border-radius: 9px !important;
    color: #FFFFFF !important;
    border: 1px solid #252F3D !important;
}

.inputText::placeholder {
    font-size: 15px !important;
    color: #FFFFFF !important;
    border: none !important;
}

.inputText:not(:placeholder-shown) {
    background-color: #ffffff!important;
    color: #000 !important;
}

.inputText:placeholder-shown {
    background-color: #252F3D !important;
    border: 1px solid #252F3D !important;
    padding-left: 10px !important;
    color: #ffffff !important;
}


/*
    
    .inputText:target {
        font-size: 17px !important;
        color: #FFFFFF !important;
        border: 1px solid #707070 !important;
    }
    
    .inputText:hover {
        font-size: 17px !important;
        color: #FFFFFF !important;
        border: 1px solid #707070 !important;
    }
    
    .inputText::placeholder {
        font-size: 17px !important;
        color: #FFFFFF !important;
        border: 1px solid #707070 !important;
    }f
    
    */

.loginBtn {
    font-size: 18px !important;
    height: 50px;
    color: black !important;
    border: 1px solid #658CC5 !important;
    border-radius: 9px !important;
    font-weight: 600;
}

.loginBtn:focus {
    box-shadow: 0 0 0 0.15rem rgb(101 140 197 / 50%)!important;
}

.loginBtn::placeholder {
    font-size: 15px !important;
}

.loginText {
    font-size: 38 px;
    font-weight: bold;
}

.signup {
    height: 40px;
    color: #000 !important;
    box-shadow: none !important;
    font-size: 16px !important;
    border-radius: 9px !important;
}

.googleLogin {
    background-color: #040F1F !important;
    display: inline-flex;
    /* color: white; */
    color: #fff !important;
    height: 50px;
    align-items: center;
    color: rgba(0, 0, 0, 0.54);
    border-radius: 9px !important;
    border: 1px solid #1F4A88 !important;
    font-size: 17px !important;
    line-height: 1.5;
}

.googleLogin:focus {
    box-shadow: 0 0 0 0.15rem rgb(31 74 136 / 20%) !important;
}

.googleLogin>div {
    margin-right: 4px !important;
    background: #040F1F !important;
    padding: 10px;
    border-radius: 5px;
}

.login-box {
    width: 98vw;
    height: 97vh;
}

.signup {
    color: #0b3358 !important;
}

.signup:hover {
    color: #042c52 !important;
}


/* @media only screen and (max-width:1024px) {
        .container-bg {
            height: 100vh !important;
            background-color: #051D39;
        }
        .login-logo {
            width: 170px !important;
            height: 70px !important;
            margin-top: -12px !important;
            margin-bottom: -10px !important;
        }
        .cardSignin {
            width: 470px !important;
            height: 560px !important;
        }
    }
    
    @media only screen and (max-width:768px) {
        .container-bg {
            height: 700px !important;
            background-color: #051D39;
        }
        .login-logo {
            width: 170px !important;
            height: 70px !important;
            margin-top: -12px !important;
            margin-bottom: -10px !important;
        }
        .cardSignin {
            width: 470px !important;
            height: 560px !important;
        }
    }
    

    
    @media only screen and (max-width:425px) {
        .container-bg {
            height: 560px !important;
            background-color: #051D39;
        }
        .login-logo {
            width: 170px !important;
            height: 70px !important;
            margin-bottom: -4px !important;
        }
        .cardSignin {
            width: 300px !important;
            height: 450px !important;
        }
    } */


/* SignUp media query 1440 */


/* @media only screen and (max-width:1440px) {
        .bg {
            height: 900px !important;
        }
    }
    
    @media only screen and (max-width:1024px) {
        .bg {
            height: 970px !important;
        }
        .login-logo {
            margin-top: 3px !important;
        }
    }
    
    @media only screen and (max-width:768px) {
        .bg {
            height: 870px !important;
        }
        .login-logo {
            margin-top: 3px !important;
        }
    }
    
    @media only screen and (max-width:375px) {
        .bg {
            height: 970px !important;
        }
        .login-logo {
            margin-top: -60px !important;
        }
    }
    
    @media only screen and (max-width:425px) {
        .bg {
            height: 970px !important;
        }
        .login-logo {
            margin-top: -60px !important;
        }
    } */