/* All svg Icon */

.iconBox-1 {
    background-color: #09254e;
    width: 115px;
    height: 116px;
    margin: auto;
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
    border-left: 1px solid black;
}

.all {
    fill: #14B8E1;
}

.iconBox1 {
    background-color: #09254e;
    width: 115px;
    height: 116px;
    cursor: pointer;
    color: white;
    border-bottom: 4px solid#14B8E1;
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
    box-shadow: inset 0px -96px 12px -3px rgb(20 184 225 / 10%);
}

.iconBox-1:hover {
    background-color: #09254e;
    width: 115px;
    height: 116px;
    cursor: pointer;
    color: white;
    border-bottom: 4px solid#14B8E1;
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
    border-right: 1px solid black;
    box-shadow: inset 0px -96px 12px -3px rgb(20 184 225 / 10%);
}

.iconBox1 .all {
    fill: #ffffff !important;
}

.iconBox1 p {
    color: #ffffff !important;
}


/* Travel svg Icon */

.travelSvg {
    opacity: 0.8;
    fill: #14B8E1;
}

.iconBox-2 {
    background-color: #09254e;
    width: 115px;
    height: 116px;
    border-left: 1px solid black;
}

.iconBox-2:hover {
    background-color: #09254e;
    width: 115px;
    height: 116px;
    cursor: pointer;
    color: white;
    border-bottom: 4px solid#14B8E1;
    border-right: 1px solid#000;
    box-shadow: inset 0px -96px 12px -3px rgb(20 184 225 / 10%);
}

.iconBox2 {
    background-color: #09254e;
    width: 115px;
    height: 116px;
    cursor: pointer;
    color: white;
    border-bottom: 4px solid#14B8E1;
    box-shadow: inset 0px -96px 12px -3px rgb(20 184 225 / 10%);
}

.iconBox2 .travelSvg {
    fill: #ffffff !important;
}

.iconBox2 p {
    color: #ffffff !important;
}


/* Event Icon */

.eventSvg {
    opacity: 0.8;
    fill: #14B8E1;
}

.iconBox-3 {
    background-color: #09254e;
    width: 115px;
    height: 116px;
    border-left: 1px solid black;
}

.iconBox-3:hover {
    background-color: #09254e;
    width: 115px;
    height: 116px;
    cursor: pointer;
    color: white;
    border-bottom: 4px solid#14B8E1;
    box-shadow: inset 0px -96px 12px -3px rgb(20 184 225 / 10%);
}

.iconBox3 {
    background-color: #09254e;
    width: 115px;
    height: 116px;
    cursor: pointer;
    color: white;
    border-bottom: 4px solid#14B8E1;
    box-shadow: inset 0px -96px 12px -3px rgb(20 184 225 / 10%);
}

.iconBox3 .eventSvg {
    fill: #ffffff !important;
}

.iconBox3 p {
    color: #ffffff !important;
}


/* Real state Icon */

.realStateSvg {
    opacity: 0.8;
    fill: #14B8E1;
}

.iconBox-4 {
    background-color: #09254e;
    width: 115px;
    height: 116px;
    border-left: 1px solid black;
}

.iconBox-4:hover {
    cursor: pointer;
    color: white;
    border-bottom: 4px solid#14B8E1;
    box-shadow: inset 0px -96px 12px -3px rgb(20 184 225 / 10%);
}

.iconBox4 {
    cursor: pointer;
    color: white;
    background-color: #09254e;
    width: 115px;
    height: 116px;
    border-left: 1px solid black;
    border-bottom: 4px solid#14B8E1;
    box-shadow: inset 0px -96px 12px -3px rgb(20 184 225 / 10%);
}

.iconBox4 .realStateSvg {
    fill: #ffff;
}

.iconBox4 p {
    color: #ffffff !important;
}


/* hospitality Icon */

.hospitalitySvg {
    opacity: 0.8;
    fill: #14B8E1;
}

.iconBox-5 {
    background-color: #09254e;
    width: 115px;
    height: 116px;
    border-left: 1px solid black;
}

.iconBox-5:hover {
    cursor: pointer;
    color: white;
    border-bottom: 4px solid#14B8E1;
    box-shadow: inset 0px -96px 12px -3px rgb(20 184 225 / 10%);
}

.iconBox5 {
    cursor: pointer;
    color: white;
    background-color: #09254e;
    border-bottom: 4px solid#14B8E1;
    width: 115px;
    height: 116px;
    border-left: 1px solid black;
    box-shadow: inset 0px -96px 12px -3px rgb(20 184 225 / 10%);
}

.iconBox5 .hospitalitySvg {
    fill: #ffff;
}

.iconBox5 p {
    color: #ffffff !important;
}


/* Business Icon */

.businessSvg {
    opacity: 0.8;
    fill: #14B8E1;
}

.iconBox-6 {
    background-color: #09254e;
    width: 115px;
    height: 116px;
    border-left: 1px solid black;
}

.iconBox-6:hover {
    cursor: pointer;
    color: white;
    border-bottom: 4px solid#14B8E1;
    background-color: #09254e;
    width: 115px;
    height: 116px;
    border-left: 1px solid black;
    box-shadow: inset 0px -96px 12px -3px rgb(20 184 225 / 10%);
}

.iconBox6 {
    cursor: pointer;
    color: white;
    border-bottom: 4px solid#14B8E1;
    background-color: #09254e;
    width: 115px;
    height: 116px;
    border-left: 1px solid black;
    box-shadow: inset 0px -96px 12px -3px rgb(20 184 225 / 10%);
}

.iconBox6 .businessSvg {
    fill: #ffff;
}

.iconBox6 p {
    color: #ffffff !important;
}


/* Education Icon */

.educationSvg {
    opacity: 0.8;
    fill: #14B8E1;
}

.iconBox-7 {
    background-color: #09254e;
    width: 115px;
    height: 116px;
    border-left: 1px solid black;
}

.iconBox-7:hover {
    cursor: pointer;
    color: white;
    border-bottom: 4px solid#14B8E1;
    width: 115px;
    height: 116px;
    border-left: 1px solid black;
    box-shadow: inset 0px -96px 12px -3px rgb(20 184 225 / 10%);
}

.iconBox7 {
    cursor: pointer;
    color: white;
    background-color: #09254e;
    border-bottom: 4px solid#14B8E1;
    width: 115px;
    height: 116px;
    border-left: 1px solid black;
    box-shadow: inset 0px -96px 12px -3px rgb(20 184 225 / 10%);
}

.iconBox7 .educationSvg {
    fill: #ffff;
}

.iconBox7 p {
    color: #ffffff !important;
}


/* Restaurant Svg */

.restaurantSvg {
    opacity: 0.8;
    fill: #14B8E1;
}

.iconBox-8 {
    background-color: #09254e;
    border-left: 1px solid black;
    width: 115px;
    height: 116px;
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
}

.iconBox-8:hover {
    cursor: pointer;
    color: white;
    border-bottom: 4px solid#14B8E1;
    border-left: 1px solid black;
    width: 115px;
    height: 116px;
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
    box-shadow: inset 0px -96px 12px -3px rgb(20 184 225 / 10%);
}

.iconBox8 {
    cursor: pointer;
    color: white;
    background-color: #09254e;
    border-bottom: 4px solid#14B8E1;
    border-left: 1px solid black;
    width: 115px;
    height: 116px;
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
    box-shadow: inset 0px -96px 12px -3px rgb(20 184 225 / 10%);
}

.iconBox8 .restaurantSvg {
    fill: #ffff;
}

.iconBox8 p {
    color: #ffffff !important;
}