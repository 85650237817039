    .btn-close {
        box-sizing: content-box;
        width: 1em;
        height: 1em;
        padding: .25em .25em;
        color: #000;
        background: transparent url(../../static/svg/cross.svg) center/1em auto no-repeat;
        border: 0;
        border-radius: .25rem;
        opacity: .5;
    }
    
    .btn-close:focus {
        outline: 0;
        box-shadow: none;
        opacity: 1;
    }
    
    .modal-header .btn-close {
        padding: 0.9rem 1.1rem!important;
        margin: -.5rem -.5rem -.5rem auto;
    }
    
    .modal-header {
        display: flex;
        flex-shrink: 0;
        align-items: center;
        justify-content: space-between;
        padding: 6px !important;
        border-bottom: none !important;
        border-radius: 0 !important;
        border-top-right-radius: 12px !important;
        border-top-left-radius: 12px !important;
    }
    
    .modal-body {
        position: relative;
        flex: 1 1 auto;
        padding: 1rem;
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
    }
    
    .modal-content {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        pointer-events: auto;
        background-color: #05142a;
        background-clip: padding-box;
        border: 2px solid #1f4a88;
        border-radius: 13px;
        outline: 0;
    }
    
    @media (min-width: 992px) {
        .modal-lg,
        .modal-xl {
            max-width: 549px;
        }
    }
    
    .allInputField {
        display: block;
        width: 85%;
        margin: auto;
        padding: .375rem .75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #212529;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        -webkit-appearance: none;
        appearance: none;
        border-radius: .25rem;
        transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    }
    
    #RequestBtn {
        background-color: #14B8E1 !important;
        font-size: 18px !important;
        font-weight: 500 !important;
        border: none;
        color: #000;
        width: 143px;
        margin-right: 38px;
    }
    /* Extra small devices (phones, 600px and down) */
    
    @media only screen and (max-width: 600px) {
        #RequestBtn {
            background-color: #14B8E1 !important;
            font-size: 15px !important;
            font-weight: 500 !important;
            border: none;
            color: #000;
            width: 121px;
            margin-right: 23px;
        }
    }
    /* Small devices (portrait tablets and large phones, 600px and up) */
    
    @media only screen and (min-width: 600px) {
        #RequestBtn {
            background-color: #14B8E1 !important;
            font-size: 16px !important;
            font-weight: 500 !important;
            border: none;
            color: #000;
            width: 132px;
            margin-right: 36px;
        }
    }
    /* Medium devices (landscape tablets, 768px and up) */
    
    @media only screen and (min-width: 768px) {
        #RequestBtn {
            background-color: #14B8E1 !important;
            font-size: 16px !important;
            font-weight: 500 !important;
            border: none;
            color: #000;
            width: 128px;
            margin-right: 35px;
        }
    }
    /* Large devices (laptops/desktops, 992px and up) */
    
    @media only screen and (min-width: 992px) {
        #RequestBtn {
            background-color: #14B8E1 !important;
            font-size: 18px !important;
            font-weight: 500 !important;
            border: none;
            color: #000;
            width: 143px;
            margin-right: 38px;
        }
    }
    /* Extra large devices (large laptops and desktops, 1200px and up) */
    
    @media only screen and (min-width: 1200px) {
        #RequestBtn {
            background-color: #14B8E1 !important;
            font-size: 18px !important;
            font-weight: 500 !important;
            border: none;
            color: #000;
            width: 143px;
            height: 40px;
            margin-right: 38px;
        }
    }