#mobileFilterDropdown {
    background-color: #05142A !important;
    padding: 0px !important;
    padding: 1px !important;
    position: absolute !important;
    right: 80px;
    z-index: 2;
    width: 44rem !important;
    margin: auto !important;
    border-radius: 9px;
    transform: translate(736px, 48px) !important;
}

.filterSearchBox {
    background-color: #09254E;
    height: 60px;
    padding-top: 6px;
    border-top: 1px solid black;
}

#inputFieldFilter {
    background-color: #05142a !important;
    color: #546472;
    height: 37px;
    background-image: url("../../static/svg/selectArrow.svg") !important;
    font-size: 15px;
    border: 1px solid #546472;
    box-shadow: none;
}

.dropdown-toggle::after {
    display: none;
}

#seaarchFiled {
    position: relative;
    display: flex;
    background-color: #040f1f;
    flex-wrap: wrap;
    align-items: stretch;
    width: 92% !important;
    margin: auto !important;
}

.selectCity {
    width: 85%;
    margin-bottom: 12px;
    font-size: 15px;
}

.checkBoxFilterMobile label {
    margin-left: 7px !important;
    margin-top: 4px !important;
}

.checkBoxFilterMobile>.form-check-input {
    width: 1.3em;
    height: 1.3em;
    margin-top: .25em;
    vertical-align: top;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 1px solid #546472;
    -webkit-appearance: none;
    appearance: none;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
}

.checkBoxFilterMobile>.form-check-input:checked {
    background-color: #14B8E1 !important;
    border-color: #000000 !important;
}

.btnFilterMobile {
    background-color: #14B8E1 !important;
    height: 33px !important;
    font-size: 14px !important;
    font-weight: 600;
    color: #07162c !important;
    position: relative;
    top: 70px;
}

.react-calendar__tile:disabled {
    background-color: #f0f0f0;
    display: none;
}

.headMSearchInputField {
    padding: 0.375rem 0.75rem !important;
    font-size: 15px !important;
    font-weight: 400;
    /* line-height: 1.5; */
    height: 40px;
    color: #a4bac6 !important;
    background-color: #040f1f !important;
    background-clip: padding-box;
    /* border: 1px solid #ced4da; */
    -webkit-appearance: none;
    appearance: none;
    margin-top: 4px !important;
    border: none !important;
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
}

.searchMIconField {
    display: flex;
    align-items: center;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    height: 40px;
    line-height: 1.5 !important;
    color: #212529;
    margin-top: 4px !important;
    text-align: center;
    white-space: nowrap;
    background-color: #040F1F!important;
    border: none !important;
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
}

.layoutFilterXs {
    padding-left: 23px;
    font-size: 17px;
    font-weight: 700;
}

.calendar {
    margin-left: 32px;
}

.dropdownSearchBtn {
    box-shadow: none !important;
}

.dropdownSearchBtn:focus {
    box-shadow: none !important;
}

#calendarStyle {
    width: 350px;
    max-width: 100%;
    background: #05142A !important;
    border: none !important;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
}

.react-calendar {
    width: 350px;
    max-width: 100%;
    background: #05142A !important;
    color: #ffffff !important;
    padding-left: 12px;
    border: none !important;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
}

.react-calendar__navigation {
    height: 38px;
    margin-bottom: 1em;
    display: none !important;
}

.react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
    border-radius: 50% !important;
}

.react-calendar__month-view__weekdays__weekday>abbr {
    text-decoration: none !important;
}

.react-calendar__tile:enabled:hover {
    background-color: #367197;
}

@media (max-width: 991px) {
    #mobileFilterDropdown {
        right: 0 !important;
        left: auto !important;
    }
    #mobileFilterDropdown {
        background-color: #05142A !important;
        padding: 0px !important;
        padding: 1px !important;
        position: absolute !important;
        right: 80px;
        z-index: 2;
        width: 44rem !important;
        margin: auto !important;
        border-radius: 9px;
        transform: translate(778px, 48px) !important;
    }
    .selectCity {
        background-color: #05142A;
        border: 1px solid#546472 !important;
        color: #A4BAC6;
        margin-top: 12px;
        width: 84%;
        margin-bottom: 12px;
        margin-right: 32px !important;
        font-size: 17px;
        height: 35px;
        box-shadow: none !important;
    }
    .selectCity:focus {
        background-color: #05142A;
        border: 1px solid#546472 !important;
        color: #A4BAC6;
        margin-top: 15px;
        width: 84%;
        margin-bottom: 12px;
        margin-right: 32px !important;
        font-size: 17px;
        height: 45px;
        box-shadow: none !important;
    }
    #canvasBox {
        width: 50% !important;
        height: 98vh !important;
        margin-top: 56px !important;
        background-color: #05142A !important;
        background-image: none;
        color: #000 !important;
    }
    .bd-highlight {
        display: block !important;
        text-align: center;
        padding-top: 6px !important;
        margin: 5px 0 0 0!important;
    }
    #sectionOne {
        width: 100% !important;
        text-align: center !important;
        margin: 19px 0 0 0!important;
    }
    #sectionTwo {
        width: 100% !important;
        display: flex !important;
        flex-direction: column;
        align-items: center;
        word-spacing: 1px;
        position: relative;
        top: 5px;
        /* margin-bottom: 26px !important; */
        padding-top: 3px !important;
    }
    .logoFooter {
        width: 69px !important;
        margin-top: 17px;
    }
    #iconFace {
        height: 36px !important;
    }
    #icon1 {
        width: 53px !important;
    }
    .visit-filter {
        text-align: end;
    }
    .btnFilterMobile {
        background-color: #14B8E1 !important;
        height: 33px !important;
        font-size: 14px !important;
        font-weight: 600;
        color: #07162c !important;
        position: relative;
        top: 70px;
        margin-right: 23px;
    }
    .footerContainer {
        margin-bottom: 42px;
    }
}

@media (max-width: 768px) {
    .visit-filter {
        position: absolute !important;
        left: 25;
        bottom: 34%;
        right: 40px;
        margin: 0px 10px;
    }
    #mobileFilterDropdown {
        background-color: #05142A !important;
        padding: 0px !important;
        padding: 1px 4px 27px !important;
        position: absolute !important;
        right: 80px;
        z-index: 2;
        width: 42rem !important;
        margin: auto !important;
        border-radius: 9px;
        transform: translate(701px, 42px) !important;
    }
    .selectCity {
        background-color: #05142A;
        border: 1px solid#546472 !important;
        color: #A4BAC6;
        margin-top: 12px;
        width: 84%;
        margin-bottom: 12px;
        margin-right: 32px !important;
        font-size: 17px;
        height: 45px;
        box-shadow: none !important;
    }
    .selectCity:focus {
        background-color: #05142A;
        border: 1px solid#546472 !important;
        color: #A4BAC6;
        margin-top: 12px;
        width: 84%;
        margin-bottom: 12px;
        margin-right: 32px !important;
        font-size: 17px;
        height: 45px;
        box-shadow: none !important;
    }
    .bd-highlight {
        display: block !important;
        text-align: center;
        padding-top: 6px !important;
        margin: 5px 0 0 0!important;
    }
    #sectionOne {
        width: 100% !important;
        text-align: center !important;
        margin: 19px 0 0 0!important;
    }
    #sectionTwo {
        width: 100% !important;
        display: flex !important;
        flex-direction: column;
        align-items: center;
        word-spacing: 1px;
        position: relative;
        /* top: 5px; */
        /* margin-bottom: 26px !important; */
        /* padding-top: 3px !important; */
    }
    .logoFooter {
        width: 69px !important;
        margin-top: 17px;
    }
    #iconFace {
        height: 36px !important;
    }
    #icon1 {
        width: 53px !important;
    }
}

@media (max-width: 667px) {
    #mobileFilterDropdown {
        background-color: #05142A !important;
        padding: 0px !important;
        padding: 1px 4px 27px !important;
        position: absolute !important;
        right: 80px;
        z-index: 2;
        width: 41rem !important;
        margin: auto !important;
        border-radius: 9px;
        transform: translate(663px, 42px) !important;
    }
}

@media (max-width: 480px) {
    #mobileFilterDropdown {
        background-color: #05142A !important;
        padding: 0px !important;
        padding: 1px !important;
        position: absolute !important;
        right: 80px;
        inset: 0px 0px auto auto !important;
        z-index: 2;
        width: 26.7rem !important;
        height: 100vh;
        margin: auto !important;
        padding-left: 4px !important;
        transform: translate(425px, 40px) !important;
    }
    #cities * {
        width: 40% !important;
    }
    .dropdownSearchBtn:focus {
        box-shadow: none !important;
        left: 10px !important;
    }
    .dropdownSearchBtn {
        box-shadow: none !important;
        left: 10px !important;
    }
    .noscroll {
        overflow: hidden;
    }
    .selectCity {
        background-color: #05142A;
        border: 1px solid#546472 !important;
        color: #A4BAC6;
        margin-top: 12px;
        width: 100%;
        margin-bottom: 12px;
        margin-right: 32px !important;
        font-size: 17px;
        height: 45px;
        box-shadow: none !important;
    }
    .selectCity:focus {
        background-color: #05142A;
        border: 1px solid#546472 !important;
        color: #A4BAC6;
        margin-top: 12px;
        width: 98%;
        margin-bottom: 12px;
        margin-right: 32px !important;
        font-size: 17px;
        height: 45px;
        box-shadow: none !important;
    }
    .totalSpace {
        width: 91% !important;
    }
    .layoutFilterXs {
        padding-left: 23px;
        font-size: 20px;
        font-weight: bold;
    }
    .layoutXs {
        margin-left: 22px !important;
    }
    .visit-filter {
        position: absolute !important;
        left: 0;
        bottom: 26% !important;
        right: 0;
        margin: 0px 10px;
        text-align: center;
    }
    .btnFilterMobile {
        background-color: #14B8E1 !important;
        height: 38px !important;
        font-size: 18px !important;
        font-weight: 600;
        width: 84%;
        position: relative;
        top: 0;
    }
    .calendar {
        margin-left: 5px;
    }
}

@media (max-width: 375px) {
    #mobileFilterDropdown {
        background-color: #05142A !important;
        padding: 0px !important;
        padding: 1px !important;
        position: absolute !important;
        right: 80px;
        inset: 0px 0px auto auto !important;
        z-index: 2;
        width: 23.6rem !important;
        height: 100vh !important;
        padding-left: 4px !important;
        transform: translate(376px, 41px) !important;
    }
    .noscroll {
        overflow: hidden;
    }
    .visit-filter {
        position: absolute !important;
        left: 0;
        bottom: 21% !important;
        right: 0;
        margin: 0px 10px;
    }
    .selectCity {
        background-color: #05142A;
        border: 1px solid#546472 !important;
        color: #A4BAC6;
        margin-top: 12px;
        width: 98% !;
        margin-bottom: 12px;
        margin-right: 32px !important;
        font-size: 17px;
        height: 40px;
        box-shadow: none !important;
    }
    .selectCity:focus {
        background-color: #05142A;
        border: 1px solid#546472 !important;
        color: #A4BAC6;
        margin-top: 12px;
        width: 98%;
        margin-bottom: 12px;
        margin-right: 32px !important;
        font-size: 17px;
        height: 40px;
        box-shadow: none !important;
    }
    .totalSpace {
        width: 91% !important;
    }
    .layoutFilterXs {
        padding-left: 23px;
        font-size: 17px;
        font-weight: 700;
    }
    .layoutXs {
        margin-left: 22px !important;
    }
    .btnFilterMobile {
        background-color: #14B8E1 !important;
        height: 33px !important;
        font-size: 14px !important;
        font-weight: 600;
        width: 84%;
        position: relative;
        top: 0;
        bottom: 0;
        margin: auto;
    }
    .calendar {
        margin-left: 5px;
    }
    .form-select {
        width: 100% !important;
    }
}